export const VEHICLES_NUM_TO_RANK = 3;
export const ELECTRIC_SUBSEGMENT = 'Electric';

export const TYPE_JELLYBEANS = {
  suv: {
    url: '/suv/',
    name: 'SUV',
    alt: 'go to SUV type page',
  },
  sedan: {
    url: '/sedan/',
    name: 'Sedan',
    alt: 'go to Sedan type page',
  },
  coupe: {
    url: '/coupe/',
    name: 'Coupe',
    alt: 'go to Coupe type page',
  },
  hatchback: {
    url: '/hatchback/',
    name: 'Hatchback',
    alt: 'go to Hatchback type page',
  },
  truck: {
    url: '/truck/',
    name: 'Truck',
    alt: 'go to Truck type page',
  },
  wagon: {
    url: '/wagon/',
    name: 'Wagon',
    alt: 'go to Wagon type page',
  },
  convertible: {
    url: '/convertible/',
    name: 'Convertible',
    alt: 'go to Convertible type page',
  },
  minivan: {
    url: '/minivan/',
    name: 'Minivan',
    alt: 'go to Minivan type page',
  },
  electric: {
    url: '/electric-car',
    name: 'Electric',
    alt: 'go to Electric type page',
  },
  hybrid: {
    url: '/hybrid/',
    name: 'Hybrid',
    alt: 'go to Hybrid type page',
  },
};

export const REPLACE_WITH_IMAGE = {
  'chevrolet/trailblazer/2025':
    'https://media.ed.edmunds-media.com/chevrolet/trailblazer/2025/oem/2025_chevrolet_trailblazer_4dr-suv_activ_fq_oem_1_600.jpg',
  'mazda/cx-30/2025':
    'https://media.ed.edmunds-media.com/mazda/cx-30/2025/oem/2025_mazda_cx-30_4dr-suv_25-s-carbon-edition_fq_oem_1_600.jpg',
  'buick/encore-gx/2025':
    'https://media.ed.edmunds-media.com/buick/encore-gx/2025/oem/2025_buick_encore-gx_4dr-suv_avenir_fq_oem_1_600.jpg',
  'mazda/cx-50/2025':
    'https://media.ed.edmunds-media.com/mazda/cx-50/2025/oem/2025_mazda_cx-50_4dr-suv_25-turbo-meridian-edition_fq_oem_1_600.jpg',
  'honda/cr-v/2025':
    'https://media.ed.edmunds-media.com/honda/cr-v/2025/oem/2025_honda_cr-v_4dr-suv_ex-l_fq_oem_1_600.jpg',
  'mazda/cx-5/2025':
    'https://media.ed.edmunds-media.com/mazda/cx-5/2025/oem/2025_mazda_cx-5_4dr-suv_25-carbon-turbo_fq_oem_1_600.jpg',
  'kia/sorento/2025':
    'https://media.ed.edmunds-media.com/kia/sorento/2025/oem/2025_kia_sorento_4dr-suv_x-line-sx-prestige_fq_oem_1_600.jpg',
  'hyundai/santa-fe/2025':
    'https://media.ed.edmunds-media.com/hyundai/santa-fe/2025/oem/2025_hyundai_santa-fe_4dr-suv_calligraphy_fq_oem_1_600.jpg',
  'mitsubishi/outlander/2024':
    'https://media.ed.edmunds-media.com/mitsubishi/outlander/2023/oem/2023_mitsubishi_outlander_4dr-suv_se_fq_oem_1_600.jpg',
  'honda/passport/2025':
    'https://media.ed.edmunds-media.com/honda/passport/2025/oem/2025_honda_passport_4dr-suv_black-edition_fq_oem_1_600.jpg',
  'toyota/land-cruiser/2025':
    'https://media.ed.edmunds-media.com/toyota/land-cruiser/2025/oem/2025_toyota_land-cruiser_4dr-suv_1958_fq_oem_1_600.jpg',
  'jeep/grand-cherokee/2025':
    'https://media.ed.edmunds-media.com/jeep/grand-cherokee/2025/oem/2025_jeep_grand-cherokee_4dr-suv_overland_fq_oem_1_600.jpg',
  'kia/telluride/2025':
    'https://media.ed.edmunds-media.com/kia/telluride/2025/oem/2025_kia_telluride_4dr-suv_sx-prestige-x-pro_fq_oem_1_600.jpg',
  'hyundai/palisade/2025':
    'https://media.ed.edmunds-media.com/hyundai/palisade/2025/oem/2025_hyundai_palisade_4dr-suv_calligraphy_fq_oem_1_600.jpg',
  'toyota/grand-highlander/2025':
    'https://media.ed.edmunds-media.com/toyota/grand-highlander-hybrid/2025/oem/2025_toyota_grand-highlander-hybrid_4dr-suv_max-limited_fq_oem_3_600.jpg',
  'gmc/yukon/2024': 'https://media.ed.edmunds-media.com/gmc/yukon/2023/oem/2023_gmc_yukon_4dr-suv_at4_fq_oem_1_600.jpg',
  'chevrolet/suburban/2025':
    'https://media.ed.edmunds-media.com/chevrolet/suburban/2025/oem/2025_chevrolet_suburban_4dr-suv_high-country_fq_oem_3_600.jpg',
  'ford/expedition/2024':
    'https://media.ed.edmunds-media.com/ford/expedition/2022/oem/2022_ford_expedition_4dr-suv_limited_fq_oem_1_600.jpg',
  'bmw/x1/2025': 'https://media.ed.edmunds-media.com/bmw/x1/2025/oem/2025_bmw_x1_4dr-suv_m35i_fq_oem_1_600.jpg',
  'mercedes-benz/glb-class/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/glb-class/2025/oem/2025_mercedes-benz_glb-class_4dr-suv_amg-glb-35_fq_oem_1_600.jpg',
  'mercedes-benz/gla-class/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/gla-class/2025/oem/2025_mercedes-benz_gla-class_4dr-suv_amg-gla-35_fq_oem_1_600.jpg',
  'genesis/gv70/2025':
    'https://media.ed.edmunds-media.com/genesis/gv70/2025/oem/2025_genesis_gv70_4dr-suv_35t-sport_fq_oem_1_600.jpg',
  'mercedes-benz/glc-class/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/glc-class/2025/oem/2025_mercedes-benz_glc-class_4dr-suv_amg-glc-43_fq_oem_1_600.jpg',
  'acura/rdx/2025':
    'https://media.ed.edmunds-media.com/acura/rdx/2025/oem/2025_acura_rdx_4dr-suv_base_fq_oem_1_600.jpg',
  'mercedes-benz/gle-class/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/gle-class/2025/oem/2025_mercedes-benz_gle-class_4dr-suv_amg-gle-53_fq_oem_1_600.jpg',
  'porsche/cayenne/2025':
    'https://media.ed.edmunds-media.com/porsche/cayenne/2025/oem/2025_porsche_cayenne_4dr-suv_base_fq_oem_2_600.jpg',
  'genesis/gv80-coupe/2025':
    'https://media.ed.edmunds-media.com/genesis/gv80-coupe/2025/oem/2025_genesis_gv80-coupe_4dr-suv_35t-e-supercharger_fq_oem_1_600.jpg',
  'lexus/gx/2025':
    'https://media.ed.edmunds-media.com/lexus/gx/2025/oem/2025_lexus_gx_4dr-suv_550-luxuryplus_fq_oem_1_600.jpg',
  'audi/q7/2025': 'https://media.ed.edmunds-media.com/audi/q7/2025/oem/2025_audi_q7_4dr-suv_prestige_fq_oem_1_600.jpg',
  'acura/mdx/2025':
    'https://media.ed.edmunds-media.com/acura/mdx/2025/oem/2025_acura_mdx_4dr-suv_base_fq_oem_2_600.jpg',
  'mercedes-benz/gls-class/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/gls-class/2025/oem/2025_mercedes-benz_gls-class_4dr-suv_amg-gls-63_fq_oem_1_600.jpg',
  'lincoln/navigator/2024':
    'https://media.ed.edmunds-media.com/lincoln/navigator/2022/oem/2022_lincoln_navigator_4dr-suv_black-label_fq_oem_1_600.jpg',
  'bmw/x7/2025': 'https://media.ed.edmunds-media.com/bmw/x7/2025/oem/2025_bmw_x7_4dr-suv_m60i_fq_oem_1_600.jpg',
  'mercedes-benz/maybach-gls/2025':
    'https://media.ed.edmunds-media.com/mercedes-benz/maybach-gls/2025/oem/2025_mercedes-benz_maybach-gls_4dr-suv_gls-600_fq_oem_1_600.jpg',
  'bentley/bentayga/2024':
    'https://media.ed.edmunds-media.com/bentley/bentayga/2024/oem/2024_bentley_bentayga_4dr-suv_a-v8_fq_oem_1_600.jpg',
  'land-rover/range-rover/2025':
    'https://media.ed.edmunds-media.com/land-rover/range-rover/2025/oem/2025_land-rover_range-rover_4dr-suv_p530-autobiography_fq_oem_1_600.jpg',
  'tesla/model-y/2025':
    'https://media.ed.edmunds-media.com/tesla/model-y/2025/oem/2025_tesla_model-y_4dr-suv_performance_fq_oem_1_600.jpg',
  'audi/sq5/2025':
    'https://media.ed.edmunds-media.com/audi/sq5/2023/oem/2023_audi_sq5_4dr-suv_prestige_fq_oem_1_600.jpg',
  'audi/sq5-sportback/2025':
    'https://media.ed.edmunds-media.com/audi/sq5-sportback/2023/oem/2023_audi_sq5-sportback_4dr-suv_prestige_fq_oem_1_600.jpg',
  'porsche/cayenne-coupe/2025/turbo-gt':
    'https://media.ed.edmunds-media.com/porsche/cayenne-coupe/2025/oem/2025_porsche_cayenne-coupe_4dr-suv_turbo-gt_fq_oem_1_600.jpg',
  'lamborghini/urus/2024':
    'https://www.edmunds.com/assets/m/cs/blt9724af8b88341973/6621fc35bb637259e41dd0e1/2024_lamborghini_urus-performante_front.jpg',
  'audi/sq7/2025':
    'https://media.ed.edmunds-media.com/audi/sq7/2025/oem/2025_audi_sq7_4dr-suv_prestige_fq_oem_1_600.jpg',
  'mercedes-benz/gls-class/2025/amg-gls-63':
    'https://media.ed.edmunds-media.com/mercedes-benz/gls-class/2025/oem/2025_mercedes-benz_gls-class_4dr-suv_amg-gls-63_fq_oem_1_600.jpg',
  'bmw/alpina-xb7/2025':
    'https://media.ed.edmunds-media.com/bmw/alpina-xb7/2025/oem/2025_bmw_alpina-xb7_4dr-suv_base_fq_oem_1_600.jpg',
  'cadillac/escalade/2024/v':
    'https://media.ed.edmunds-media.com/cadillac/escalade/2024/oem/2024_cadillac_escalade_4dr-suv_v_fq_oem_1_600.jpg',
};
export const REPLACE_WITH_FETCHPRIORITY = [
  'chevrolet/trailblazer/2025',
  'mazda/cx-30/2025',
  'buick/encore-gx/2025',
  'mazda/cx-50/2025',
  'honda/cr-v/2025',
  'mazda/cx-5/2025',
  'kia/sorento/2025',
  'hyundai/santa-fe/2025',
  'mitsubishi/outlander/2024',
  'honda/passport/2025',
  'toyota/land-cruiser/2025',
  'jeep/grand-cherokee/2025',
  'kia/telluride/2025',
  'hyundai/palisade/2025',
  'toyota/grand-highlander/2025',
  'gmc/yukon/2024',
  'chevrolet/suburban/2025',
  'ford/expedition/2024',
  'bmw/x1/2025',
  'mercedes-benz/glb-class/2025',
  'mercedes-benz/gla-class/2025',
  'genesis/gv70/2025',
  'mercedes-benz/glc-class/2025',
  'acura/rdx/2025',
  'mercedes-benz/gle-class/2025',
  'porsche/cayenne/2025',
  'genesis/gv80-coupe/2025',
  'lexus/gx/2025',
  'audi/q7/2025',
  'acura/mdx/2025',
  'mercedes-benz/gls-class/2025',
  'lincoln/navigator/2024',
  'bmw/x7/2025',
  'mercedes-benz/maybach-gls/2025',
  'bentley/bentayga/2024',
  'land-rover/range-rover/2025',
  'tesla/model-y/2025',
  'audi/sq5/2025',
  'audi/sq5-sportback/2025',
  'porsche/cayenne-coupe/2025/turbo-gt',
  'lamborghini/urus/2024',
  'audi/sq7/2025',
  'mercedes-benz/gls-class/2025/amg-gls-63',
  'bmw/alpina-xb7/2025',
  'cadillac/escalade/2024/v',
];
export const REPLACE_TYPES = ['electric', 'suv', 'hybrid'];
